.ant-layout-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0;
  box-shadow: 0 4px 9px -3px rgba(#000, 0.2);
  z-index: 99;
}

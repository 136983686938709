.ant-layout {
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .content-layout {
    min-height: 100vh;
    transition: all 0.2s;
  }

  &.collapsed {
    .content-layout {
      margin-left: 80px;
    }
  }
}

.media {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover .anticon-select,
  &:hover .anticon-delete {
    display: inline-block;
  }

  .anticon-delete {
    display: none;
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 1.5rem;
    color: #dc4234;
    z-index: 9;
  }

  .anticon-select {
    display: none;
    position: absolute;
    left: -8px;
    top: -8px;
    width: 22px;
    height: 22px;
    background: transparent;
    padding: 2px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    border-radius: 50%;
    cursor: pointer;
    z-index: 99;

    &.active {
      display: inline-block;
      background: #1890ff;
    }
  }
}

.banner {
  position: relative;

  &:hover .anticon-delete {
    display: inline-block;
  }

  .anticon-delete {
    display: none;
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 1.5rem;
    color: #dc4234;
    z-index: 9;
  }

  .ant-image-img {
    max-height: 320px;
  }
}

.ant-space-item {
  small {
    user-select: text;
    cursor: text;
  }
}

/* Progress Bar */
.progressbar {
  counter-reset: step;

  &.sber li,
  &.delivery li {
    width: 16%;
  }

  &.sber.delivery li {
    width: 14%;
  }

  li {
    list-style-type: none;
    width: 20%;
    float: left;
    font-size: 0.6rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;

    &:before {
      position: relative;
      width: 26px;
      height: 26px;
      content: counter(step);
      counter-increment: step;
      line-height: 24px;
      border: 2px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
      z-index: 9;
    }

    &.active:before {
      content: "\2713";
    }

    &.error:before {
      content: "\2717";
    }

    &:after {
      width: 100%;
      height: 2px;
      content: "";
      position: absolute;
      background-color: #7d7d7d;
      top: 13px;
      left: -50%;
    }

    &:first-child:after {
      content: none;
    }

    &.active {
      color: green;

      &:before {
        border-color: #55b776;
      }

      + li:after {
        background-color: #55b776;
      }
    }

    &.error {
      color: red;

      &:before {
        border-color: red;
      }
    }
  }
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  font-size: 0.75rem;
}

.ant-layout-sider {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow: auto;

  .brand {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    padding: 8px 16px;
    box-shadow: 0 2px 9px -3px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }

  .logo {
    width: 100%;
    height: 56px;
    background-image: url(../images/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &-collapsed {
    .logo {
      background-image: url(../images/logo_min.svg);
    }
  }
}

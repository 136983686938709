.gridContainer {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }

    max-width: 100%;
}

.photoCard {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.photoImage {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    border-radius: 0.375rem;
    cursor: pointer;
}

.photoFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.photoLabel {
    margin-left: 0.5rem;
}

.selectAllButton {
    padding: 0.5rem 1rem;
    background-color: #3490dc;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}

.selectAllButtonPublish {
    padding: 0.5rem 1rem;
    background-color: #3490dc;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    margin-left: 7px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 0.5rem;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
}

.largePhoto {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 0.5rem;
}
